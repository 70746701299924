import React from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { GET_SHOP } from '../../queries'

export default function StoresBox(props) {

    const { loading, error, data } = useQuery(GET_SHOP);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    return (
        <div className="row app-bg-image-white">
            <div className="col-12">
                <div id="tiendas" className="text-center "><h3 className="m-0 pt-4 pr-4 pl-4 w-100 text-center text-bold ">CONOCE NUESTRAS TIENDAS</h3></div>
                <ButtonGroup className={!props.isMobile ? "flex-wrap justify-content-center w-100 store-container " : "flex-wrap justify-content-center w-100 store-container-mobile "}>

                    {data.getShop.map((shop, key) => {
                        return (
                            <a key={key} href={shop.url} target="_blank" rel="noopener noreferrer" className={!props.isMobile ? "store-box text-center" : "store-box-mobile text-center"}>
                                <img
                                    className={!props.isMobile ? "shop-img" : "shop-img-mobile"}
                                    src={shop.logo}
                                    alt={shop.name}
                                />
                            </a>
                        )
                    })}
                </ButtonGroup>
            </div>
        </div>
    );
}
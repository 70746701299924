import React from 'react'
//import { useQuery } from '@apollo/client';
//import { GET_PROMO } from '../queries'
import TransactionDataModal from '../components/modals/TransactionDataModal';

// const ShopBox = () => {
//     const { loading, error, data } = useQuery(GET_PROMO, {
//         variables: {
//             identifier: 1
//         }
//     });

//     if (loading) return 'Loading...';
//     if (error) return `Error! ${error.message}`;

//     const preventa = data.getPromo.qty
//     return <div className="shop-box">
//         {(preventa === 0) ?
//             <img className="w-100" src={'/img/preventa/preventa_soldout.png'} alt='banner' />
//             :
//             <>
//                 <img className="w-100" src={'/img/torneo_oficial.png'} alt='banner' />
//                 <TransactionDataModal identifier={1} title={'Torneo oficial Kick On TCG'} price={10000} actualQty={preventa} dataType="torneooficial"></TransactionDataModal>
//             </>
//         }
//     </div>
// }

// const TournamentDetail = () => {
//     return (
//         <>
//             <div className="row">
//                 <div className="col-12">
//                     <h1>¡Torneo oficial 2023!</h1>
//                 </div>
//             </div>
//             <div className="row mt-2">
//                 <div className="col-12 text-center">
//                     <h4><b>Formato:</b> FULL.</h4>
//                 </div>

//             </div>
//             <div className="row mt-2  text-center">
//                 <div className="col-12" style={{ textAlign: "-webkit-center" }}>
//                     <table className="tg">
//                         <thead>
//                             <tr>
//                                 <th className="tg-baqh">Lugar</th>
//                                 <th className="tg-baqh">Premio</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             <tr>
//                                 <td className="tg-baqh">1er lugar</td>
//                                 <td className="tg-baqh">$50.000</td>

//                             </tr>
//                             <tr>
//                                 <td className="tg-baqh">2do lugar</td>
//                                 <td className="tg-baqh">$30.000</td>

//                             </tr>
//                             <tr>
//                                 <td className="tg-baqh">3er lugar</td>
//                                 <td className="tg-baqh">$15.000</td>
//                             </tr>
//                             <tr>
//                                 <td className="tg-baqh">Top 4 a 8</td>
//                                 <td className="tg-baqh">Dos sobres Clase Mundial</td>
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//             <div className="row mt-3">
//                 <div className="col-12">
//                     <h4>Tipo de torneo:</h4>
//                 </div>
//             </div>
//             <div className="row">
//                 <div className="col-12"><p>Fase play off, luego 4tos de final a eliminación directa.</p></div>
//             </div>
//             <div className="row mt-3">
//                 <div className="col-12 p-1" style={{ backgroundColor: '#ffbb00', borderRadius: '5px' }}>
//                     <h5>Inscripción: $10.000 (incluye planchado + bebestible y 1 sobre de cada edición)</h5>
//                 </div>
//             </div>
//             <div className="row mt-3">
//                 <div className="col-12">
//                     <h3> <a href="https://maps.app.goo.gl/pbCxyqD8AC2av2YJ8" target='_blank' rel="noreferrer">Ubicación Club El Dado</a></h3>
//                 </div>
//             </div></>
//     )
// }

export default function Shop(props) {

    /*  const { loading, error, data } = useQuery(GET_PROMO, {
         variables: {
             identifier: 1
         }
     });
     let qty = 0
     if (loading) return 'Loading...';
     if (error) return `Error! ${error.message}`;
 
     if (typeof data.getPromo[0] !== 'undefined') {
         qty = data.getPromo[0].qty
         console.log('data', data.getPromo[0].qty)
     } */

    return (
        !props.isMobile ?

            <div id="shop" className='app-bg-image-white' style={{ paddingTop: props.topMargin }}>
                {/*          <div style={{ paddingTop: props.topMargin * 2 }} className="container">
                    <div className="row text-center p-2">
                        <div className="col-6 text-center">
                            <ShopBox />
                        </div>
                        <div className="col-6 text-center">
                            <TournamentDetail />
                        </div>
                    </div>
                </div> */}

                <div className="container" style={{ paddingTop: props.topMargin }}>


                    <div className="row" >
                        <div className="col-sm text-center p-2">
                            <div className="shop-box bottom-90">
                                <img className="primary-promo-img w-100" src='/img/cyber2.png' alt='banner' />
                                <TransactionDataModal identifier={1} title={'Promo 1: $75000'} price={5000}></TransactionDataModal>
                            </div>
                        </div>
                        <div className="col-sm text-center p-2">
                            <div className="shop-box bottom-90">
                                <img className="primary-promo-img w-100" src='/img/cyber3.png' alt='banner' />
                                <TransactionDataModal identifier={1} title={'Promo 2: $130000'} price={5000}></TransactionDataModal>
                            </div>
                        </div>
                        {/*        <div className="col-sm text-center p-2">
                            <div className="shop-box">
                                <img className="primary-promo-img w-100" src='/img/promoNavidad.png' alt='banner' />
                                <p><b>Mazo de 55 cartas, con dos dados y playmat más 3 sobres el debut,
                                    3 sobres pretemporada clase mundial de 7 cartas cada uno y 3 cartas promo exclusivas.</b></p>
                                <TransactionDataModal identifier={2} title={'Caja pretemporada + mazo $20.000'} price={20000}></TransactionDataModal>
                            </div>
                        </div> */}

                    </div>
                    {/*
                    <div className="row">
                        <div className="col-sm text-center p-2">
                        <div className="shop-box">
                                <img className="w-100" src='/img/promoNavidad.png' alt='banner' />
                                <p><b>Mazo de 55 cartas, con dos dados y playmat más 3 sobres el debut,
                                    3 sobres pretemporada clase mundial de 7 cartas cada uno y 3 cartas promo exclusivas.</b></p>
                                <TransactionDataModal identifier={2} title={'Caja pretemporada + mazo'} price={20000}></TransactionDataModal>
                            </div>
                        </div>
                        <div className="col-sm text-center p-2">
                            <div className="shop-box">
                                <img className="w-100" src={'/img/preventa/promo3.webp'} alt='banner' />
                                <TransactionDataModal identifier={4} title={'Promocion 3: Display de 20 cartas + Mazo primera edición'} price={38000}></TransactionDataModal>
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="shop-box">
                                <img className="w-100" src={'/img/preventa/promo4.webp'} alt='banner' />
                                <TransactionDataModal identifier={5} title={'Promocion 4: Display 20 cartas + 6 sobres primera edición'} price={40000}></TransactionDataModal>
                            </div>
                        </div> 
                    </div>*/}

                </div>
            </div>
            :
            <div id="shop" className='app-bg-image-white'>
                <div style={{ paddingTop: props.topMargin, paddingBottom: '10px' }} className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="shop-box">
                                <img className="w-100" src='/img/cyber2.png' alt='banner' />
                                <TransactionDataModal identifier={1} title={'Promo 1: $75.000'} price={75000}></TransactionDataModal>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="shop-box">
                                <img className="w-100" src='/img/cyber3.png' alt='banner' />
                                <TransactionDataModal identifier={2} title={'Promo 2: $130.000'} price={130000}></TransactionDataModal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

import React, { Fragment, useState } from "react";
import { Modal, Button, Form, Container, Col, Spinner } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { CREATE_TRANSACTION_DATA } from "../../mutations";
import axios from "axios";

export default function TransactionDataModal(props) {
    const [show, setShow] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [pago, setPago] = useState(false);
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [dni, setDni] = useState("");
    const [phone, setPhone] = useState("+569");
    const [address, setAddress] = useState("");
    const [addressNumber, setAddress2] = useState("");
    const [department, setDepartment] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [teamName, setTeamName] = useState('');

    const [createTransactionData] = useMutation(CREATE_TRANSACTION_DATA);

    const handleClose = () => {
        setShow(false);
        setName("");
        setLastName("");
        setEmail("");
        setDni("");
        setPhone("");
        setAddress("");
        setAddress2("");
        setDepartment("");
        setQuantity(1);
        setPago(false);
        setTeamName("");
    };
    const handleShow = () => setShow(true);
    const handleSpinner = (val) => setSpinner(val);
    const handlePago = (val) => setPago(val);
    const handleName = (value) => setName(value.target.value);
    const handleLastName = (value) => setLastName(value.target.value);
    const handleEmail = (value) => setEmail(value.target.value);
    const handleDni = (value) => setDni(value.target.value);
    const handlePhone = (value) => setPhone(value.target.value);
    const handleAddress = (value) => setAddress(value.target.value);
    const handleAddress2 = (value) => setAddress2(value.target.value);
    const handleDepartment = (value) => setDepartment(value.target.value);
    const handleQuantity = (value) => setQuantity(value.target.value);
    const handleTeamName = (value) => setTeamName(value.target.value);
 
    const handleGlobalId = (value) => {
        var pago = document.getElementById("pago");
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.mercadopago.cl/integrations/v1/web-payment-checkout.js";
        script.setAttribute("data-preference-id", value);
        pago.appendChild(script);
        handlePago(true);
    };

    const createTransaction = (b) => {
        b.preventDefault();
        const data = {
            name: name,
            surname: lastName,
            email: email,
            date_created: new Date(),
            phone: {
                area_code: "56",
                number: phone.split('+56')[1],
            },
            identification: {
                type: "DNI",
                number: dni,
            },
            address: {
                street_name: address,
                street_number: addressNumber + " " + department,
            },
            title: props.title,
            unit_price: props.price,
            quantity: quantity,
        };
        handleSpinner(true);
        axios.post('api/v1/mercadopago/compra', data)
            .then((response) => {
                handleSpinner(false);
                let global_id = response.data.global_id;
                createTransactionData({
                    variables: {
                        name: name,
                        lastName: lastName,
                        dni: dni,
                        phone: phone,
                        email: email,
                        address: address,
                        subAddress: addressNumber,
                        department: department,
                        promoIdentifier: props.identifier,
                        quantity: parseInt(quantity),
                        dataType: props.dataType,
                        mercadopagoId: global_id,
                        teamName: teamName,
                    },
                });
                handleGlobalId(response.data.global_id);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    return (
        <Fragment>
            <Button
                onClick={handleShow}
                className="app-btn-secondary-no-border  w-100"
            >
                {props.title}
            </Button>

            <Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Form onSubmit={createTransaction}>
                    <Modal.Header>
                        <Modal.Title>{props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="pl-3 pr-3">
                            <Form.Group controlId="NombreUsuario">
                                <Form.Row>
                                    <Col>
                                        <Form.Control required placeholder="Nombre" value={name} onChange={(value) => handleName(value)} />
                                    </Col>
                                    <Col>
                                        <Form.Control required placeholder="Apellido" value={lastName} onChange={(value) => handleLastName(value)} />
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group controlId="CorreoElectronico">
                                <Form.Row>
                                    <Col sm={6}>
                                        <Form.Control required type="email" placeholder="Correo electrónico" value={email} onChange={(value) => handleEmail(value)} />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control
                                            required
                                            type="text"
                                            name="dni"
                                            placeholder="Rut (16123456-1)"
                                            pattern="[0-9]{8}-[A-Za-z0-9]{1}"
                                            value={dni}
                                            onChange={(value) => handleDni(value)}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                            <Form.Group controlId="Telefono">
                                <Form.Row>
                                    <Col sm={6}>
                                        <Form.Control required placeholder="Teléfono (+56978392046)" value={phone} onChange={(value) => handlePhone(value)} />
                                    </Col>
                                    <Col sm={6}>
                                        {props.address && <Form.Control required placeholder="Calle, Ciudad, Comuna" value={address} onChange={(value) => handleAddress(value)} />}
                                        <Form.Control placeholder="Nombre equipo" value={teamName} onChange={(value)=>{handleTeamName(value)}}/>
                                    </Col>
                                </Form.Row>
                            </Form.Group>

                            {props.address &&
                                <Form.Group controlId="Dirección">
                                    <Form.Row>
                                        <Col sm={6}>
                                            <Form.Control required type="number" placeholder="Número" value={addressNumber} onChange={(value) => handleAddress2(value)} />
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Control placeholder="Departamento" type="number" value={department} onChange={(value) => handleDepartment(value)} />
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                            }

                            {props.qty &&
                                <Form.Group controlId="Promocion">
                                    <Form.Row>
                                        <Form.Label column sm={2}>
                                            Cantidad
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Form.Control type="number" required placeholder="Cantidad" value={quantity} onChange={(value) => handleQuantity(value)} />
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                            }
                        </Container>
                    </Modal.Body>
                    <Modal.Footer className="p-0">
                        <Form.Row>
                            <Col>
                                <Button variant="secondary" onClick={handleClose} style={{ minWidth: "140px", maxHeight: "45px" }}>
                                    Cerrar
                                </Button>
                            </Col>
                            <Col>
                                {!pago ? (
                                    <Button type="submit" className="app-btn" style={{ minWidth: "140px", maxHeight: "45px" }}>
                                        {" "}
                                        {spinner ? <Spinner animation="border" variant="warning" style={{ maxHeight: '35px' }} /> : "Pagar"}
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Form.Row>
                    </Modal.Footer>
                </Form>
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                        <form style={{minWidth: '140px'}} id="pago" method="GET" action="/exito" className="pay-btn"></form>
                        </div>
                    </div>
                </div>
    
            </Modal>
        </Fragment>
    );
}

import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
//import Cookie from 'universal-cookie';
//import LoginModal from '../components/modals/LoginModal';
//import RegisterModal from '../components/modals/RegisterModal';

//const cookie = new Cookie()

export default function NavBar(props) {

   const [rko, setRko] = useState([]);

   useEffect(() => {
      fetch("/api/v1/rko")
         .then(res =>
            res.json()
         )
         .then(rko => { setRko(rko.data); })
         .catch(e => {
            console.error(e)
         })
   }, [])

   if (!props.isMobile) {
      return (
         <nav id="navbar" className="navbar navbar-dark d-flex">
            <div className="container">
               <div className="pl-2 pt-2 pb-2">
                  <a className="nav-link" href="/"><img src="/img/logo-navbar.png" alt="" /> </a>
               </div>
               <div className="pl-2 pt-2 pb-2">
                  <a className="app-link pl-2 pr-0" target='__blank' href="https://listado.mercadolibre.cl/_CustId_628102182?item_id=MLC2825954278&category_id=MLC1161&seller_id=628102182&client=recoview-selleritems&recos_listing=true" rel="noopener noreferrer">COMPRAR</a>
               </div>
               <div className="pl-2 pt-2 pb-2">
                  <NavDropdown className="text-white" title={<label className="text-white m-0">APRENDE</label>} id="basic-nav-dropdown">
                     <NavDropdown.Item className="app-color" href="#howtoplay">Videos</NavDropdown.Item>
                     <NavDropdown.Item className="app-color" href={rko.filter(rko => rko.type === 'pyp').length !== 0 ? rko.filter(rko => rko.type === 'pyp')[0].url : ''} target="_blank">Imprime y juega</NavDropdown.Item>
                  </NavDropdown>
               </div>
               <div className="pl-2 pt-2 pb-2">
                  <NavDropdown className="text-white" title={<label className="text-white m-0">TORNEOS</label>} id="basic-nav-dropdown">
                     <NavDropdown.Item className="app-color" href={rko.filter(rko => rko.type === 'rko').length !== 0 ? rko.filter(rko => rko.type === 'rko')[0].url : ''} target="_blank">Reglamento</NavDropdown.Item>
                     <NavDropdown.Item className="app-color" href={rko.filter(rko => rko.type === 'errata').length !== 0 ? rko.filter(rko => rko.type === 'errata')[0].url : ''} target="_blank">Fe de erratas</NavDropdown.Item>
                     <NavDropdown.Item className="app-color" href={rko.filter(rko => rko.type === 'jo').length !== 0 ? rko.filter(rko => rko.type === 'jo')[0].url : ''} target="_blank">Juego organizado</NavDropdown.Item>
                     <NavDropdown.Item className="app-color" href={rko.filter(rko => rko.type === 'registro').length !== 0 ? rko.filter(rko => rko.type === 'registro')[0].url : ''} target="_blank">Registro de equipo</NavDropdown.Item>

                  </NavDropdown>
               </div>
               <div className="pl-2 pt-2 pb-2">
                  <a className="app-link pl-2 pr-0" href="/#tiendas" rel="noopener noreferrer">TIENDAS</a>
               </div>
               <div className="pl-2 pt-2 pb-2">
                  <a className="app-link pl-2 pr-0" href="/cartas" rel="noopener noreferrer">CARTAS</a>
               </div>
               {/* <div className="pl-2 pt-2 pb-2">
                  <a className="app-link pl-2 pr-0" href="/mazos" rel="noopener noreferrer">MAZOS</a>
               </div>
               <div className="pl-2 pt-2 pb-2">
                  <a className="app-link pl-2 pr-0" href="/torneos/buscador" rel="noopener noreferrer">TORNEOS</a>
               </div> */}
               <div className="pl-2 pt-2 pb-2">
                  <img src="/img/icons/tabletopia.png" alt="ig" style={{ maxWidth: '25px' }} />
                  <a className="app-link pl-2 pr-0" href="https://tabletopia.com/games/kickon" target="_blank" rel="noopener noreferrer">Kick On online</a>
               </div>
               <div className="pl-2 pt-2 pb-2">
                  {/*  {cookie.get('ktk') ?
                     <div className="row">
                        <div className="col-5">
                           <NavDropdown title={<><img src="img/icons/user.png" alt="user" className="user-icon app-color"></img><label className="ml-2 mr-2 app-color">{props.user}</label></>} id="basic-nav-dropdown">
                              <NavDropdown.Item className="app-color" href="/perfil">Perfil</NavDropdown.Item>
                              <NavDropdown.Item className="app-color" href="/" onClick={handleLogOut} >Cerrar sesión</NavDropdown.Item>
                           </NavDropdown>
                        </div>
                     </div>
                     :
                     <Fragment>
                        <LoginModal setLoggedIn={props.setLoggedIn} setUser={props.setUser} user={props.user} role={props.role} setRole={props.setRole} />
                        <RegisterModal />
                     </Fragment>
                  } */}
               </div>
            </div>
         </nav>
      )
   } else {
      return (
         <Navbar id="navbar" collapseOnSelect expand="lg" className="navbar navbar-dark d-flex justify-content-center">
            <div className="row p-0 text-center">
               <div className="col-12">
                  <Navbar.Brand href="/"><img className="" alt="brand" src="/img/logo-navbar.png" style={{ margin: '1px' }} />
                  </Navbar.Brand>
                  <a className='juega' href='https://tabletopia.com/games/kickon' style={{ color: '#ffc600', fontFamily: 'Helvetica' }}>JUEGA AQUI</a>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav" style={{ right: '200px' }} >
                     <Nav>
                        <div className="row">
                           <div className="col-2">
                              <div className="pl-2 pt-2 pb-2"></div>
                           </div>
                           <div className="col-8">
                                           
                           <Nav.Link className="text-light" href="/cartas">CARTAS </Nav.Link>
                              <Nav.Link className="text-light" href={rko.filter(rko => rko.type === 'rko').length !== 0 ? rko.filter(rko => rko.type === 'rko')[0].url : ''} target="_blank">REGLAMENTO</Nav.Link>
                              <Nav.Link className="text-light" href={rko.filter(rko => rko.type === 'errata').length !== 0 ? rko.filter(rko => rko.type === 'errata')[0].url : ''} target="_blank">ERRATAS</Nav.Link>
                              <Nav.Link className="app-color" href="https://listado.mercadolibre.cl/_CustId_628102182?item_id=MLC2825954278&category_id=MLC1161&seller_id=628102182&client=recoview-selleritems&recos_listing=true"> <b>COMPRAR</b> </Nav.Link>
                              <Nav.Link className="text-light" href={rko.filter(rko => rko.type === 'jo').length !== 0 ? rko.filter(rko => rko.type === 'jo')[0].url : ''} target="_blank">JUEGO ORGANIZADO</Nav.Link>
                              <Nav.Link className="text-light" href={rko.filter(rko => rko.type === 'registro').length !== 0 ? rko.filter(rko => rko.type === 'registro')[0].url : ''} target="_blank">REGISTRO EQUIPO</Nav.Link>
                              <Nav.Link className="text-light" href={rko.filter(rko => rko.type === 'pyp').length !== 0 ? rko.filter(rko => rko.type === 'pyp')[0].url : ''} target="_blank"> <b>IMPRIME Y JUEGA</b></Nav.Link>
                              <Nav.Link className="text-light" href="#tiendas">NUESTRAS TIENDAS</Nav.Link>
                              {/* <Nav.Link href="/tienda">PREVENTA</Nav.Link> */}
                              {/* <Nav.Link href="/mazos">MAZOS</Nav.Link>
                              <Nav.Link href="/torneos/buscador">TORNEOS</Nav.Link> */}
                           </div>
                           <div className="col-2">

                           </div>
                        </div>
                        {/* {cookie.get('ktk') ?
                           <NavDropdown title={<><img src="img/icons/user.png" alt="user" className="user-icon app-color"></img><label className="ml-2 mr-2 app-color">{props.user}</label></>} id="basic-nav-dropdown">
                              <div className="row">
                                 <div className="col-12">
                                    <NavDropdown.Item className="app-color" href="/perfil">Perfil</NavDropdown.Item>
                                    <NavDropdown.Item className="app-color" href="/" onClick={handleLogOut} >Cerrar sesión</NavDropdown.Item>
                                 </div>
                              </div>
                           </NavDropdown>
                           :
                           <div className="row mb-2">
                              <div className="col-6">
                                 <LoginModal isMobile={props.isMobile} setLoggedIn={props.setLoggedIn} setUser={props.setUser} user={props.user} role={props.role} setRole={props.setRole} />
                              </div>
                              <div className="col-6">
                                 <RegisterModal isMobile={props.isMobile} />
                              </div>
                           </div>
                        } */}
                     </Nav>
                  </Navbar.Collapse>
               </div>
            </div>
         </Navbar >
      )
   }
}